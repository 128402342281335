<template>
  <v-navigation-drawer :class="['aScroll', visibleTextMenu ? 'is-expanded' : '']">
    <v-list>
      <template v-for="(item, index) in menuItems">
        <v-list-item
          v-if="!item.subItems ||(item.subItems && !item.subItems.length)"
          :key="`item-${index}`"
          :prepend-icon="item.icon"
          :title.attr="item.title"
          :to="localePath({'name': item.linkPath})"
          @click="visibleTextMenu = false"
        >
          <template #title>
            {{ item.title }}
          </template>
        </v-list-item>
        <v-list-group v-else :key="`group-${index}`">
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              :prepend-icon="item.icon"
              :title.attr="item.title"
            >
              <template #title>
                {{ item.title }}
              </template>
            </v-list-item>
          </template>
          <v-list-item
            v-for="(subItem, i) in item.subItems"
            :key="i"
            :title.attr="subItem.title"
            :prepend-icon="subItem.icon"
            :to="localePath({'name': subItem.linkPath})"
            @click="visibleTextMenu = false"
          >
            <template #title>
              {{ subItem.title }}
            </template>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
    <div
      v-if="menuItems.length"
      :class="['themeSwitch themeSwitch--menu', visibleTextMenu ? 'is-dirty' : '', 'text-center cursor-pointer']"
    >
      <div
        class="themeSwitch__item flex-center"
        @click="visibleTextMenu = !visibleTextMenu"
      >
        <v-icon icon="ct:expand" size="x-small" />
      </div>
      <div
        class="themeSwitch__item flex-center"
        @click="visibleTextMenu = !visibleTextMenu"
      >
        <v-icon icon="ct:expand" size="x-small" />
      </div>
      <div class="themeSwitch__slider" />
    </div>
  </v-navigation-drawer>
</template>
<script setup lang="ts">
const localePath = useLocalePath();
const { t } = useI18n();

type MenuItem = {
  title: string
  icon: string
  linkPath?: string
  subItems?: MenuItem[]
}

const menuItems = computed<MenuItem[]>(() => [
  { title: t("main_menu.buyout_list"), icon: "ct:dashboard", linkPath: "cashtec" },
  // {
  //   title: t("main_menu.settings"),
  //   icon: "mdi-cog",
  //   subItems: [
  //     { title: t("main_menu.settings_bonus"), icon: "mdi-cog", linkPath: "setup-bonus" }
  //   ]
  // },
  { title: t("main_menu.settings_bonus"), icon: "ct:gift", linkPath: "cashtec-setup-bonus" },
  { title: t("main_menu.blacklist"), icon: "ct:lock-alt", linkPath: "cashtec-blacklist" },
  { title: t("main_menu.logs"), icon: "ct:pencil", linkPath: "cashtec-logs" },
  { title: t("main_menu.payments"), icon: "ct:cash", linkPath: "cashtec-payments" },
  { title: t("main_menu.chats"), icon: "ct:chat", linkPath: "cashtec-chats" },
  { title: t("main_menu.export"), icon: "ct:cloud", linkPath: "cashtec-export" },
  { title: t("main_menu.tools"), icon: "ct:network", linkPath: "cashtec-tools" }
]);

const visibleTextMenu = ref<boolean>(false);
</script>
